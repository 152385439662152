<app-intranet-container>
  <app-table-default *ngIf="es_listado"
  [data]="data"
  [columnas_tabla]="[
    'uid',
    'familia',
    'familia_enlace',
    'descripcion',
  ]"
  [nombre_columnas]="[
    'Referencia',
    'Nombre',
    'Enlace',
    'Descripcion',
  ]"
  [tipo_dato_tabla]="[
    '',
    '',
    '',
    '',
    '',
  ]"
  [alineacion_columnas]="[
    'start#start',
    'start#start',
    'start#start',
    'start#start',
    ]"
  [ancho_columnas]="[]"
  [id_nombre]="'uid'"
  [ver_boton_modal_externo]="false"
  [ver_boton_editar]="true"
  [ver_boton_borrar]="true"
  [ver_boton_imprimir]="false"
  [propiedades_para_filtro]="['articulo','referencia','descripcion']"
  [nombre_tabla]="'articulos'"
  [tiene_paginacion] = "true"
  [pagina_actual] = "pagina_actual"
  [total_paginas]="total_paginas"
  (envia_filtro)="recibe_filtro($event)"
  (envia_pagina)="recibe_pagina($event)"
  (envia_borrado)="abrir_modal_borrado($event)" 
  />
  <app-intranet-familias-form *ngIf="!es_listado && cargando === false"
  [familia]="familia"
  [es_nuevo]="id == 0"
  [id]="id"
  (envia_nueva_familia)="recibe_nueva_familia($event)"
  (envia_actualiza_familia)="recibe_actualiza_familia($event)"
/>
</app-intranet-container>
  
