import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deleteToken } from 'src/app/services/helpers/session.helper';

@Component({
  selector: 'app-intranet-sidebar',
  templateUrl: './intranet-sidebar.component.html',
  styleUrls: ['./intranet-sidebar.component.scss']
})
export class IntranetSidebarComponent {
  opcion: string;

  constructor(
    private _router: Router,
    private _toast: ToastrService,
  ) {
    const url_array = this._router.url.split('/');
    this.opcion = url_array[url_array.length - 1];
  }

  cerrar_sesion() {
    deleteToken();
    this._toast.show('Sesión cerrada');
    this._router.navigate(['/'])
  }
}
