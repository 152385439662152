import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IconDefinition} from '@fortawesome/free-regular-svg-icons';
import {faChevronRight, faSearch} from '@fortawesome/free-solid-svg-icons';
import {ToastrService} from 'ngx-toastr';
import {ArticuloModel} from 'src/app/models/entity/articulos.model';
import {GridCardsTipoUnoModel} from 'src/app/models/entity/grid_tipo_uno.model';
import {ProductosTipoUnoModel} from 'src/app/models/entity/productos_tipo_uno_model';
import {IFamilia} from 'src/app/models/interface/familias.interface';
import {LoadService} from 'src/app/services/helpers/load.service';
import {ArticuloServices} from 'src/app/services/https/articulos.services';
import {FamiliaServices} from 'src/app/services/https/familia.services';
import {SubfamiliaServices} from 'src/app/services/https/subfamilia.services';
import {globalState} from 'src/global';
import {Meta, Title} from '@angular/platform-browser';
import {FamiliaModel} from "../../../models/entity/familia.model";
import {SubfamiliaModel} from "../../../models/entity/subfamilia.model";


@Component({
  selector: 'app-catalogo-page',
  templateUrl: './catalogo-page.component.html',
  styleUrls: ['./catalogo-page.component.scss'],
  providers: [FamiliaServices, SubfamiliaServices, ArticuloServices]
})

export class CatalogoPageComponent {

  data: GridCardsTipoUnoModel[] = [];
  familias: FamiliaModel[] = [];
  subfamilias: SubfamiliaModel[] = [];
  urls: string[];
  producto: ProductosTipoUnoModel | null = null;
  pagina_actual: number = 1;
  total_paginas: number = 1;
  registros: number = 12;
  tipo_catalogo: number = 0;
  familia: string = "";
  subfamilia: string = "";
  tiene_paginacion: Boolean = false;
  icono_buscar: IconDefinition = faSearch
  icono_abrir: IconDefinition = faChevronRight
  valor_navegacion: string = "";

  form = new FormGroup({
    valor_busqueda: new FormControl<string>(''),
    valor_interno_busqueda: new FormControl<string>('')
  })

  estadoSubfamilias: { [key: number]: boolean } = {};

  constructor(
      private _familia_services: FamiliaServices,
      private _subfamilia_services: SubfamiliaServices,
      private _articulo_services: ArticuloServices,
      private _loader: LoadService,
      private _toast: ToastrService,
      private _router: Router,
      private _route: ActivatedRoute,
      private titleService: Title,
      private metaService: Meta
  ) {
    // Configurar título de la página
    this.titleService.setTitle('Catálogo de Antigüedades - Diego Reinaldos');

    // Configurar meta tags
    this.metaService.addTags([
      {
        name: 'description',
        content: 'Descubra nuestro catálogo de antigüedades restauradas: muebles antiguos, decoración vintage, piezas únicas y más. Calidad y autenticidad en cada artículo.'
      },
      {
        name: 'keywords',
        content: 'antigüedades restauradas, muebles antiguos, decoración vintage, piezas únicas, catálogo de antigüedades, artículos de colección, muebles de época, decoración rústica, objetos antiguos, arte vintage'
      }
    ]);

    // Obtener la URL actual sin los parámetros de consulta ni fragmentos
    const currentUrl = this._router.url.split('?')[0].split('#')[0];

    // Dividir en segmentos, eliminando los vacíos
    const urlSegments = currentUrl.split("/").filter(segment => segment !== "");
    this.urls = urlSegments;
    this.tipo_catalogo = urlSegments.length - 1;

    this._route.queryParams.subscribe(async (params) => {
      this.pagina_actual = params['page'] ? Number(params['page']) : 1;

      if (params['keyword']) {
        const busqueda = decodeURIComponent(params['keyword']);
        this.form.patchValue({
          valor_busqueda: busqueda,
          valor_interno_busqueda: busqueda
        });
        this.tipo_catalogo = 3;
      }
      if (this.data.length > 0) {
        await this.ngOnInit();
      }
    });
    this._route.params.subscribe(async (params) => {
      if (this.data.length > 0)
        await this.ngOnInit();
    });
  }

  async ngOnInit(): Promise<void> {
    this._loader.notifyLoadChange(true);
    this.data = [];
    this.urls = this._router.url.split('?')[0].split('#')[0].split("/").filter(segment => segment !== "");
    const valor_url = this.urls[this.urls.length - 1].split("?page=")[0];
    const es_numero = !isNaN(Number(valor_url));
    if(es_numero) {
      await this.obtener_catalogo_por_id(Number(valor_url))
    } else {
      if (!es_numero) {
        const { familias } = await this._familia_services.paginacion(1, 100, '');
        const { subfamilias } = await this._subfamilia_services.paginacion(1, 100, '');
        this.familias = familias;
        this.subfamilias = subfamilias;
        if (this.tipo_catalogo === 0) {
          await this.obtener_catalogo_tipo_familia();
        } else if (this.tipo_catalogo === 1) {
          this._route.url.subscribe(segments => {
            this.valor_navegacion = segments.map(seg => seg.path).join('/');
            this.valor_navegacion = this.valor_navegacion.replace('catalogo/', ''); // Remueve "catalogo/"
          });
          this.familia = this.urls[this.urls.length - 1];
          await this.obtener_catalogo_tipo_subfamilia();
        } else if (this.tipo_catalogo === 2) {
          this._route.url.subscribe(segments => {
            this.valor_navegacion = segments.map(seg => seg.path).join('/');
            this.valor_navegacion = this.valor_navegacion.replace('catalogo/', ''); // Remueve "catalogo/"
          });
          this.familia = this.urls[this.urls.length - 2];
          this.subfamilia = this.urls[this.urls.length - 1];
          await this.obtener_catalogo_tipo_productos();
        } else if (this.tipo_catalogo === 3) {
          console.log("si")
          await this.obtener_catalogo_tipo_busqueda();
        }
      }
    }
  }

  async obtener_catalogo_tipo_familia(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const { familias, pagina_actual, total_paginas } = await this._familia_services.paginacion(this.pagina_actual, this.registros, "");
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      familias.forEach((item: IFamilia) => {
        let titulo: string;
        let descripcion: string;
        switch(idioma) {
          case "en-EN":
            titulo = item.familia_en !== "" ? item.familia_en : item.familia
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.familia_fr !== "" ? item.familia_fr : item.familia
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.familia;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,null,item.file_name, descripcion,enlace,null, null))
      })
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_subfamilia(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where familia_enlace = '${this.familia}' AND stock > 0`
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      this.data = [];
      // convertimos a tipo de dato para usar en el componente
      for (const item of articulos) {
        let stock_subfamilia = null;
        if (globalState.identity && globalState.identity.nivel == 99) {
          stock_subfamilia = await this._subfamilia_services.obtiene_stock_subfamilia(item.uid);
        }
        let titulo: string;
        let descripcion: string;
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,null,item.file_name, descripcion,enlace,null, stock_subfamilia))
      }
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_productos(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where familia_enlace = '${this.familia}' AND  subfamilia_enlace = '${this.subfamilia}' AND stock > 0`
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo: string;
        let descripcion: string;
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, item.stock))
      })
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_por_id(uid: number): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where uid = ${uid} AND stock > 0`
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo: string;
        let descripcion: string;
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, null))
      })
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_busqueda(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      this.data = [];
      const valores = this.form.value.valor_interno_busqueda?.split(" ");
      let filtro = "where "
      valores?.forEach((item: string, index: number) => {
        filtro += `((INSTR(keywords, '${item}') > 0 AND stock > 0) OR (INSTR(referencia, '${item}') > 0 AND stock > 0))`;
        filtro += ``
        if (index < valores.length - 1) {
          filtro += ` OR `
        }
      })
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo: string;
        let descripcion: string;
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, item.stock))
      });
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async recibe_pagina(event: any): Promise<void> {
    this._loader.notifyLoadChange(true);
    this.pagina_actual = event;
    // Obtiene la URL actual sin parámetros de consulta ni fragmentos
    let currentUrl = this._router.url;
    let keyword = "";
    if (this._router.url.includes("?page")) {
      currentUrl = this._router.url.split('?page=')[0];
    } else if (this._router.url.includes("?keyword=")) {
      currentUrl = this._router.url.split('?keyword=')[0];
      keyword = this._router.url.split('?keyword=')[1].split('&')[0];
    }
    // Navega a la misma URL con el parámetro 'page'
    if (keyword !== '') {
      await this._router.navigate([currentUrl], { queryParams: { keyword: keyword, page: this.pagina_actual } });
    } else {
      await this._router.navigate([currentUrl], { queryParams: { page: this.pagina_actual } });
    }
    // Desplazamiento suave
    window.scrollTo({ top: 250, behavior: 'smooth' });
    // Reinicializa el componente
    // this.ngOnInit();
  }

  async buscar_articulos() {
    if (this.form.controls.valor_busqueda.value === "") {
      this._toast.warning("Campo de búsqueda vacío")
      return;
    } else {
    //  this.tipo_catalogo = 3;
    //  this.data = [];
      const value = encodeURI(this.form.value.valor_busqueda!!);
      this.form.patchValue({
        valor_interno_busqueda: value
      });
      await this._router.navigate(['/busqueda'], {
        queryParams: { keyword: value }
      });
    //  await this.obtener_catalogo_tipo_busqueda();
    }
  }

  toggleSubfamilias(familiaUid: number) {
    this.estadoSubfamilias[familiaUid] = !this.estadoSubfamilias[familiaUid];
  }

  async onCategoriaSeleccionada(value: any) {
    const enlace = encodeURI(value.value);
    console.log(enlace);
    if (enlace) {
      await this._router.navigate([`/catalogo/${enlace}`]); // Navegar a la nueva URL
    }
  }
}