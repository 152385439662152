import { globalState } from "src/global";
import { ISubfamilias } from "../interface/subfamilia.interface";

export class SubfamiliaModel implements ISubfamilias {
  uid: number;
  subfamilia_enlace: string;
  idioma: string;
  familia_uid: number;
  subfamilia: string;
  subfamilia_en: string;
  subfamilia_fr: string;
  descripcion: string;
  descripcion_fr: string;
  descripcion_en: string;
  file_type: string;
  file_name: string;
  file_size: string;
  fecha_alta: string;
  fecha_consulta: string | null;
  fecha_modificacion: string | null;
  usuario_alta: number;
  usuario_consulta: number;
  usuario_modificacion: number;
  consultas: number;
  modificaciones: number;
  famila_enlace: number;

  constructor(
   item: ISubfamilias
  ) {
    this.uid = item.uid;
    this.subfamilia_enlace = item.subfamilia_enlace;
    this.idioma = item.idioma;
    this.familia_uid = item.familia_uid;
    this.subfamilia = item.subfamilia;
    this.subfamilia_en = item.subfamilia_en;
    this.subfamilia_fr = item.subfamilia_fr;
    this.descripcion = item.descripcion;
    this.descripcion_fr = item.descripcion_fr;
    this.descripcion_en = item.descripcion_en;
    this.file_type = item.file_type;
    this.file_name = `${globalState.urlImg}subfamilias/${item.file_name}`;
    this.file_size = item.file_size;
    this.fecha_alta = item.fecha_alta;
    this.fecha_consulta = item.fecha_consulta;
    this.fecha_modificacion = item.fecha_modificacion;
    this.usuario_alta = item.usuario_alta;
    this.usuario_consulta = item.usuario_consulta;
    this.usuario_modificacion = item.usuario_modificacion;
    this.consultas = item.consultas;
    this.modificaciones = item.modificaciones;
    this.famila_enlace = item.famila_enlace;
  }
}