import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { IInsertaUsuario } from 'src/app/models/interface/usuario.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { SliderServices } from 'src/app/services/https/slider.services';
import { UsuariosService } from 'src/app/services/https/usuarios.services';

@Component({
  selector: 'app-intranet-usuarios',
  templateUrl: './intranet-usuarios.component.html',
  styleUrls: ['./intranet-usuarios.component.scss'],
  providers: [UsuariosService]
})
export class IntranetUsuariosComponent {
  es_listado: Boolean;
  usuario: UsuarioModel | null = null;
  id: number = 0;
  data: UsuarioModel[] = [];
  registros: number = 100;
  pagina_actual: number = 1;
  total_paginas: number = 0;
  filtro: string = '';
  cargando: boolean = true;

  constructor(
    private _router: Router,
    private  _usuario_service: UsuariosService,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal,
    private titleService: Title,
    private metaService: Meta
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 3;
    if (!this.es_listado) {
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      }
    }
  }

  async ngOnInit() {
    this.titleService.setTitle('Intranet - Antigüedades Diego Reinaldos');
    this.metaService.addTags([
    ]);
    if (this.es_listado) {
      try {
        this._loader.notifyLoadChange(true)
        this.data = await this.obtener_lista_usuarios();
      } catch (e: any) {
        this._toast.error(e)
      } finally {
        this._loader.notifyLoadChange(false)
      }
    } else {
      if (this.id !== 0) {
        try {
          this._loader.notifyLoadChange(true);
          this.usuario = await this._usuario_service.ficha_nuevo(this.id)
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
          this.cargando = false
        }
      } else {
        this.cargando = false;
      }
    }
  }

  async obtener_lista_usuarios(): Promise<UsuarioModel[]> {
    try {
      return await this._usuario_service.lista_nuevo();
    } catch (e: any) {
      return Promise.reject(e)
    }
  }

  abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "usuarios"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "usuarios"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          this._usuario_service.elimina(id);
        } catch (e: any) {
          this._toast.error(e);
          this._loader.notifyLoadChange(false);
        } finally {
          this.ngOnInit();
        }
      }
    });
  }

  async recibe_nuevo_usuario(evento: IInsertaUsuario) {
    try {
      this._loader.notifyLoadChange(true)
      const slider = await this._usuario_service.crea_nuevo(evento);
      if (slider !== null) {
        this._toast.success('Usuario creado')
        this._router.navigate(['/intranet/usuarios'])
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false)
    }
  }
  async recibe_actualiza_usuario(evento: any) {
    try {
      this._loader.notifyLoadChange(true);
      const slider = await this._usuario_service.actualiza_nuevo(this.id, evento);
      if (slider !== null) {
        this._toast.success("Usuario actualizado");
        this._router.navigate(['/intranet/usuarios'])
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
