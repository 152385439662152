import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { createSolicitudView } from '../helpers/model.helper';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IFamilia } from 'src/app/models/interface/familias.interface';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { ISubfamilias } from 'src/app/models/interface/subfamilia.interface';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { IArticulos } from 'src/app/models/interface/articulo.interface';
import { ArticuloImagenModel } from 'src/app/models/entity/articulo_imagen.model';
import { IArticuloImagenInterface } from 'src/app/models/interface/articulo_imagen.interface';

@Injectable()
export class ArticulosImagenesServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }
  
  async paginacion(pagina: number, registros: number, filtro: string): Promise<{ articulos: ArticuloModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}articulos?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const lista_articulos: ArticuloModel[] = [];
      res.data.articulos.forEach((item: IArticulos) => {
        lista_articulos.push(
          new ArticuloModel(
            item
        ));
      });
      const { pagina_actual, total_paginas } = res.data;
      return { articulos: lista_articulos, pagina_actual, total_paginas };
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async ficha_articulo(id_articulo: number): Promise<ArticuloImagenModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}articulos_imagenes/${id_articulo}`, httpOptions));
      const data: ArticuloImagenModel[] = [];
      res.data.forEach((item: IArticuloImagenInterface) => {
        data.push(new ArticuloImagenModel(item))
      })
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async inserta(id_articulo: number, archivo: File): Promise<ArticuloImagenModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
      formData.append('articulo_uid', id_articulo.toString());
      formData.append('imagen', archivo);
      formData.append('file_name', archivo.name);
      formData.append('file_type', archivo.type);
      formData.append('file_size', archivo.size.toString());
      formData.append('usuario_alta', globalState.identity?.uid.toString() || '0');

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}articulos_imagenes`, formData, httpOptions));
      
      return new ArticuloImagenModel(res.data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async borra(id_relacion: number): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    

    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}articulos_imagenes/${id_relacion}`,  httpOptions));
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

}
