<div class="container">
  <div class="grid-container pt-5">
    <ng-container *ngFor="let item of data">
      <a class="card-tipo-uno__container" [routerLink]="item.enlace ?? ''">
        <div class="d-flex justify-content-center position-relative">
          <div *ngIf="item.referencia !== null" class="position-abolute bg-danger rounded-3" style="position:absolute; top: 15px; left: 15px;"><p class="m-0 py-1 px-2 text-white">Ref: {{ item.referencia }}</p></div>
          <div *ngIf="item.stock !== null && es_admin" class="position-abolute text-bg-warning rounded-3" style="position:absolute; top: 15px; right: 15px;"><p class="m-0 py-1 px-2 text-white">Stock: {{ item.stock }}</p></div>
          <img [src]="item.imagen" alt="" class="text-center img-fluid">
        </div>
        <div class="card-tipo-uno__content">
          <h3><b>{{ item.titulo }}</b></h3>
          <p class="card-tipo-uno__descrip">{{ item.descripcion }}</p>
        </div>
        <div *ngIf="item.enlace !== null" class="card-tipo-uno__footer">
          <a [routerLink]="item.enlace">Ver</a>
        </div>
      </a>
    </ng-container>
</div>