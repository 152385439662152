<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Confirmar Borrado
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  ¿Seguro que desea eliminar {{ tipo_objeto }}? <br /><br />
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="borra_entidad()"
  >
    Borrar
  </button>
</div>
