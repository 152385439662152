<form class="form-container"  #articulo_form="ngForm" (ngSubmit)="on_submit()">
  <div class="form-header mt-4 d-flex justify-content-between">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de Subfamilia</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de Subfamilia</h6>
    <a target="_blank" *ngIf="!es_nuevo" class="btn btn-aceptar" [routerLink]="'/catalogo/' + enlace">Ver catálogo</a>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="form-group mt-3">
      <label for="" class="align-bottom">SubFamilia *</label>
      <input
        [class.invalid]="formGroup.controls.subfamilia.invalid && formGroup.controls.subfamilia.dirty && formGroup.controls.subfamilia.touched"
        [formControl]="formGroup.controls.subfamilia"
        id="subfamilia"
        name="subfamilia"
        placeholder="Subfamilia"
        required
        type="text"
        class="form-control"
        (change)="formatear_para_enlace($event)"
      >
    </div>
    <app-input
      class="mt-3"
      [control]="formGroup.controls.subfamilia_en"
      [label]="'Subfamilia Inglés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.subfamilia_fr"
      [label]="'Subfamilia Francés'"
      [type]="'text'"
      [required]="false"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'"/>
      <div class="form-group">
        <label for="familia"></label>
        <select
          [class.invalid]="formGroup.controls.familia_uid.invalid && formGroup.controls.familia_uid.dirty && formGroup.controls.familia_uid.touched && formGroup.controls.familia_uid.value == 0"
          [formControl]="formGroup.controls.familia_uid"
          id="familia"
          name="familia"
          class="form-select">
          <option disabled value="0">Selecciona familia</option>
          <option *ngFor="let familia of familias" [value]="familia.uid">
            {{familia.familia}}
          </option>
        </select>
      </div>
      
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.subfamilia_enlace"
      [label]="'Enlace *'"
      [type]="'text'"
      [placeholder]="'Ej: puerta-madera'"
      [disabled]="!es_nuevo"
      [id]="'enlace'" />
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.descripcion"
      [label]="'Descripción *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'" />
    <app-input
      class="full-width mt-3" 
      [control]="formGroup.controls.descripcion_en"
      [label]="'Descripción Inglés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'"/>
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.descripcion_fr"
      [label]="'Descripción Francés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />

    <div class="mt-3 position-relative">
      <p class="">Imagen Principal</p>
      <img *ngIf="imagen_a_mostrar !== ''" class="img-fluid border" [src]="imagen_a_mostrar">
      <div class="pb-3">
      </div>
      <button (click)="borra_imagen();" *ngIf="formGroup.value.file_name !== '' && formGroup.value.file_name !== null && formGroup.value.file_name !== undefined" class="btn btn-danger position-absolute top-0 end-0">
        <fa-icon [icon]="icono_cerrar" />
      </button>
      <input
        type="file"
        class="form-control"
        style="max-height: 40px;"
        (change)="this.formatear_archivo($event)"
        accept="image/png, image/jpeg, application/pdf"
      />
    </div>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      role="button"
      type="submit"
      class="btn btn-aceptar mt-5"
      >
      <span  *ngIf="es_nuevo">
        Crear
      </span>
      <span  *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/intranet/familias'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
