import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-paginacion-tipo-dos',
  templateUrl: './paginacion-tipo-dos.component.html',
  styleUrls: ['./paginacion-tipo-dos.component.scss']
})
export class PaginacionTipoDosComponent {
  // Parámetros de paginación
  @Input() pagina_actual!: number;
  @Input() total_paginas!: number;
  @Output() cambia_pagina: EventEmitter<number> = new EventEmitter();

  // Iconos a mostrar
  faAngleLeft: IconDefinition = faAngleLeft;
  faAngleRight: IconDefinition = faAngleRight;
  faAnglesLeft: IconDefinition = faAnglesLeft;
  faAnglesRight: IconDefinition = faAnglesRight;

  ir_a_pagina(pagina: number) {
    this.pagina_actual = pagina;
    this.cambia_pagina.emit(this.pagina_actual);
  }

  ir_a_pagina_siguiente(): void {
    console.log(this.pagina_actual);
    console.log(this.pagina_actual + 1);
    this.pagina_actual = Number(this.pagina_actual) + 1;
    this.cambia_pagina.emit(this.pagina_actual);
  }

  mostrarElipsisInicio(): boolean {
    return this.pagina_actual > 2;
  }

  mostrarElipsisFin(): boolean {
    return this.pagina_actual < this.total_paginas - 1;
  }
}
