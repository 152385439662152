<app-page-container-publico [title] = "'Quienes Somos'" (envia_datos_cfg)="recibe_datos_cfg($event)">
  <app-breadcrumb-container />
  <div class="container py-5">

    <div class="card-container">
      <app-card-icono-texto-enlace-tipo-uno *ngFor="let tarjeta of tarjetas_contacto" [item]="tarjeta" />
    </div>
    <h5 class="text-center" *ngIf="idioma ==='es-ES'">CONTÁCTANOS</h5>
    <h5 class="text-center" *ngIf="idioma ==='en-EN'">CONTACT US</h5>
    <h5 class="text-center" *ngIf="idioma ==='fr-FR'">CONTACTE NOUS</h5>
    
    <h3 class="text-center" *ngIf="idioma ==='es-ES'">Te informamos de lo que necesites sin compromiso.</h3>
    <h3 class="text-center" *ngIf="idioma ==='en-EN'">We will inform you of your needs without obligation.</h3>
    <h3 class="text-center" *ngIf="idioma ==='fr-FR'">Nous vous informerons sans engagement de votre part sur ce dont vous avez besoin. </h3>

    <div class="contact-form">
      <form id="formulario-contacto" (ngSubmit)="onSubmit()">
        <div class="grid-superior">
          <app-input
            [control]="form.controls.nombre"
            [label]="'Nombre *'"
            [placeholder]="'Juan Perez'"
            [type]="'text'"
            [id]="'nombre'"
          />
          <app-input
            [control]="form.controls.email"
            [placeholder]="'miemail@email.com'"
            [label]="'Email *'"
            [type]="'text'"
            [id]="'email'"
          />
          <app-input
            [control]="form.controls.telefono"
            [label]="'Teléfono *'"
            [placeholder]="'+34 654 98 73 21'"
            [type]="'tel'"
            [id]="'telefono'"
          />
          <app-input
            [control]="form.controls.asunto"
            [placeholder]="'Información sobre precios'"
            [label]="'Asunto *'"
            [type]="'text'"
            [id]="'asunto'"
          />
        </div>
        <app-text-editor
          [control]="form.controls.mensaje"
          [placeholder]="'Tu mensaje'"
          [id]="'mensaje'"
        />
        <div class="custom-control custom-checkbox mt-5 mb-3">
          <input
            required
            type="checkbox" 
            id="leido" 
            name="leido"
            class="custom-control-input ng-untouched ng-pristine ng-valid"
            [formControl]="form.controls.politicas"
          >
          <label _ngcontent-dvk-c101="" for="leido" class="custom-control-label">
            <span _ngcontent-dvk-c101="" class="ms-2">
              He leído y acepto la <a [routerLink]="'/politica'"><strong>Política de privacidad</strong></a>
            </span>
          </label>
        </div>
        <div class="button-container">
          <button type="submit" class="btn-message" [disabled]="!form.valid">
            <fa-icon [icon]="icono_enviar" class="btn-icon"/>
            Enviar mensaje
          </button>
        </div>
      </form>
    </div>
  </div>
    

</app-page-container-publico>