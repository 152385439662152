<form class="form-container" #articulo_form="ngForm" (ngSubmit)="on_submit()">
  <div class="form-header mt-4 d-flex justify-content-between">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de Usuario</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de Usuario</h6>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      class="mt-3 full-width"
      [control]="formGroup.controls.nombre"
      [label]="'Nombre *'"
      [type]="'text'"
      [placeholder]="'Ej: Pedro Martínez'"
      [required]="true"
      [id]="'nombre'" />
    <div class="form-group pt-3">
      <label for="idioma">Idioma *</label>
      <select
        [class.invalid]="formGroup.controls.idioma.invalid && formGroup.controls.idioma.dirty && formGroup.controls.idioma.touched && formGroup.controls.idioma.value == ''"
        [formControl]="formGroup.controls.idioma"
        id="idioma"
        name="idioma"
        class="form-select">
        <option disabled value>Selecciona idioma</option>
        <option value="es">Español</option>
        <option value="fr">Francés</option>
        <option value="en">Inglés</option>
      </select>
    </div>
    <app-input
      class="mt-3 two-cols"
      [control]="formGroup.controls.descripcion"
      [label]="'Descripción *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'descripcion'" />
    <app-input
      class="mt-3 two-cols"
      [control]="formGroup.controls.email"
      [label]="'Email *'"
      [type]="'email'"
      [placeholder]="'Ej: email@email.com'"
      [required]="true"
      [id]="'email'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.clave"
      [label]="'Contraseña *'"
      [type]="'password'"
      [placeholder]="'Ej: MiContraseña'"
      [required]="true"
      [id]="'clave'" />

    <app-input
      class="mt-3 two-cols"
      [control]="formGroup.controls.telefono"
      [label]="'Teléfono'"
      [type]="'phone'"
      [required]="false"
      [placeholder]="'Ej: 648 79 54 12'"
      [id]="'telefono'" />
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      role="button"
      type="submit"
      class="btn btn-aceptar mt-5">
      <span *ngIf="es_nuevo">
        Crear
      </span>
      <span *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/intranet/usuarios'" class="btn btn-outline-error mt-5">
      Cancelar
    </a>
  </div>
</form>
