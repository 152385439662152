import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { createSolicitudView } from '../helpers/model.helper';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IActualizaFamilia, IFamilia, IInsertaFamilia } from 'src/app/models/interface/familias.interface';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { obtainToken } from '../helpers/session.helper';

@Injectable()
export class FamiliaServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }
  
  async paginacion(pagina: number, registros: number, filtro: string): Promise<{ familias: FamiliaModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}familias?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const lista_familias: FamiliaModel[] = [];
      res.data.familias.forEach((item: IFamilia) => {
        lista_familias.push(
          new FamiliaModel(
            item.uid, item.familia_enlace, item.familia, item.familia_en, item.familia_fr, item.descripcion, item.descripcion_en, item.descripcion_fr,
            item.file_type, item.file_name, item.file_size, item.fecha_alta, item.fecha_modificacion, item.fecha_consulta, item.usuario_alta, item.usuario_consulta,
            item.usuario_modificacion, item.consultas, item.modificaciones
        ));
      });
      const { pagina_actual, total_paginas } = res.data;
      return { familias: lista_familias, pagina_actual, total_paginas };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  async inserta(datos: IInsertaFamilia): Promise<FamiliaModel>
  {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const { familia_enlace, familia, familia_en, familia_fr, descripcion_en, descripcion_fr, descripcion, archivo} = datos
    const formData = new FormData();
    formData.append('familia_enlace', familia_enlace);
    formData.append('familia', familia);
    formData.append('familia_en', familia_en);
    formData.append('familia_fr', familia_fr);
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('descripcion', descripcion);
    formData.append('imagen',archivo);
    formData.append('file_name', archivo.name);
    formData.append('file_type', archivo.type);
    formData.append('file_size', archivo.size.toString());
    formData.append('usuario_alta',globalState.identity?.uid.toString() || '0');
    formData.append('idioma', 'es');

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}familias`, formData, httpOptions));
      const { data } = res.data 
      return new FamiliaModel(
        data.uid,
        data.familia_enlace,
        data.familia,
        data.familia_en,
        data.familia_fr,
        data.descripcion,
        data.descripcion_en,
        data.descripcion_fr,
        data.file_type,
        data.file_name,
        data.file_size,
        data.fecha_alta,
        data.fecha_modificacion,
        data.fecha_consulta,
        data.usuario_alta,
        data.usuario_consulta,
        data.usuario_modificacion,
        data.consultas,
        data.modificaciones
      );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza(id: number, datos: IActualizaFamilia): Promise<FamiliaModel>
  {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const { familia_enlace, familia, familia_en, familia_fr, descripcion_en, descripcion_fr, descripcion, archivo} = datos
    const formData = new FormData();
    formData.append('uid', id.toString())
    formData.append('familia_enlace', familia_enlace);
    formData.append('familia', familia);
    formData.append('familia_en', familia_en);
    formData.append('familia_fr', familia_fr);
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('descripcion', descripcion);
    formData.append('usuario_alta',globalState.identity?.uid.toString() || '0');
    formData.append('idioma', 'es');
    if (archivo !== undefined) {
      formData.append('imagen',archivo);
      formData.append('file_name', archivo.name);
      formData.append('file_type', archivo.type);
      formData.append('file_size', archivo.size.toString());
    }

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}familias/actualiza`, formData, httpOptions));
      console.log(res);
      const { data } = res;
      return new FamiliaModel(
        data.uid,
        data.familia_enlace,
        data.familia,
        data.familia_en,
        data.familia_fr,
        data.descripcion,
        data.descripcion_en,
        data.descripcion_fr,
        data.file_type,
        data.file_name,
        data.file_size,
        data.fecha_alta,
        data.fecha_modificacion,
        data.fecha_consulta,
        data.usuario_alta,
        data.usuario_consulta,
        data.usuario_modificacion,
        data.consultas,
        data.modificaciones
      );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }

    
  async ficha(id_familia: number): Promise<FamiliaModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}familias/${id_familia}`, httpOptions));
      return new FamiliaModel(
        data.uid,
        data.familia_enlace,
        data.familia,
        data.familia_en,
        data.familia_fr,
        data.descripcion,
        data.descripcion_en,
        data.descripcion_fr,
        data.file_type,
        data.file_name,
        data.file_size,
        data.fecha_alta,
        data.fecha_modificacion,
        data.fecha_consulta,
        data.usuario_alta,
        data.usuario_consulta,
        data.usuario_modificacion,
        data.consultas,
        data.modificaciones
      )
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  

  async obtiene_familia_por_enlace(enlace: string): Promise<FamiliaModel | null>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const params = {
      enlace: enlace
    }

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}familias/obtener_por_familia`, params, httpOptions));
      const { data } = res
      if (data === null) {
        return null;
      }
      return new FamiliaModel(
        data.uid,
        data.familia_enlace,
        data.familia,
        data.familia_en,
        data.familia_fr,
        data.descripcion,
        data.descripcion_en,
        data.descripcion_fr,
        data.file_type,
        data.file_name,
        data.file_size,
        data.fecha_alta,
        data.fecha_modificacion,
        data.fecha_consulta,
        data.usuario_alta,
        data.usuario_consulta,
        data.usuario_modificacion,
        data.consultas,
        data.modificaciones
      );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id_articulo: number): Promise<Boolean> {
    const token = obtainToken();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }).set('Authorization', 'Bearer ' + token),
    };
    try {
      const res = await firstValueFrom(this._http.delete<any>(`${this.url}familias/${id_articulo}`, httpOptions));
      return true;
    } catch (e: any) {
      return false;
    }
  }

}
