<app-intranet-container>
  <app-table-default *ngIf="es_listado"
  [data]="data"
  [columnas_tabla]="[
    'id_slider',
    'titulo',
    'orden',
  ]"
  [nombre_columnas]="[
    'Referencia',
    'Titulo',
    'Orden',
  ]"
  [tipo_dato_tabla]="[
    '',
    '',
    '',
  ]"
  [alineacion_columnas]="[
    'start#start',
    'start#start',
    'center#center',
    ]"
  [ancho_columnas]="[
  ]"
  [ancho_columnas]="[]"
  [id_nombre]="'id_slider'"
  [ver_boton_modal_externo]="false"
  [ver_boton_editar]="true"
  [ver_boton_borrar]="true"
  [ver_boton_imprimir]="false"
  [propiedades_para_filtro]="['articulo','referencia','descripcion']"
  [nombre_tabla]="'articulos'"
  [tiene_paginacion] = "true"
  [pagina_actual] = "pagina_actual"
  [total_paginas]="total_paginas"
  [tiene_paginacion]="false"
  (envia_borrado)="abrir_modal_borrado($event)" 
  />
  <app-intranet-slider-form *ngIf="!es_listado && cargando === false"
      [es_nuevo]="id == 0"
      [id]="id"
      [slider]="slider"
      (envia_nuevo_slider)="recibe_nuevo_slider($event)"
      (envia_actualiza_slider)="recibe_actualiza_slider($event)"
    />
</app-intranet-container>
  
