import { globalState } from "src/global";
import { IFamilia } from "../interface/familias.interface";

export class FamiliaModel implements IFamilia {
  uid: number;
  familia_enlace: string;
  familia: string;
  familia_en: string;
  familia_fr: string;
  descripcion: string;
  descripcion_en: string;
  descripcion_fr: string;
  file_type: string;
  file_name: string;
  file_size: string;
  fecha_alta: string;
  fecha_modificacion: string | null;
  fecha_consulta: string | null;
  usuario_alta: number;
  usuario_consulta: number;
  usuario_modificacion: number;
  consultas: number;
  modificaciones: number;

  constructor(
    uid: number,
    familia_enlace: string,
    familia: string,
    familia_en: string,
    familia_fr: string,
    descripcion: string,
    descripcion_en: string,
    descripcion_fr: string,
    file_type: string,
    file_name: string,
    file_size: string,
    fecha_alta: string,
    fecha_modificacion: string | null,
    fecha_consulta: string | null,
    usuario_alta: number,
    usuario_consulta: number,
    usuario_modificacion: number,
    consultas: number,
    modificaciones: number
  ) {
    this.uid = uid;
    this.familia_enlace = familia_enlace;
    this.familia = familia;
    this.familia_en = familia_en;
    this.familia_fr = familia_fr;
    this.descripcion = descripcion;
    this.descripcion_en = descripcion_en;
    this.descripcion_fr = descripcion_fr;
    this.file_type = file_type;
    this.file_name = `${globalState.urlImg}familias/${file_name}`;
    this.file_size = file_size;
    this.fecha_alta = fecha_alta;
    this.fecha_modificacion = fecha_modificacion;
    this.fecha_consulta = fecha_consulta;
    this.usuario_alta = usuario_alta;
    this.usuario_consulta = usuario_consulta;
    this.usuario_modificacion = usuario_modificacion;
    this.consultas = consultas;
    this.modificaciones = modificaciones;
  }
}