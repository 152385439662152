import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { IInsertaUsuario } from 'src/app/models/interface/usuario.interface';

@Component({
  selector: 'app-intranet-usuarios-form',
  templateUrl: './intranet-usuarios-form.component.html',
  styleUrls: ['./intranet-usuarios-form.component.scss']
})
export class IntranetUsuariosFormComponent {
  @Input() es_nuevo!: boolean;
  @Input() id!: number;
  @Input() usuario!: UsuarioModel | null;
  @Output() envia_nuevo_usuario: EventEmitter<IInsertaUsuario> = new EventEmitter();
  @Output() envia_actualiza_usuario:EventEmitter<IInsertaUsuario> = new EventEmitter();

  formGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    clave: new FormControl<string>('', [Validators.required, Validators.minLength(3)]),
    nombre: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required]),
    idioma: new FormControl<string>('', [Validators.required]),
    descripcion: new FormControl<string>(''),
  });


  constructor( private _toast: ToastrService){}

  ngOnInit() {
    if (!this.es_nuevo && this.usuario) {
      this.rellenar_formulario(this.usuario);
    }
  }

  rellenar_formulario(usuario: UsuarioModel) {
    this.formGroup.patchValue({
      email: usuario.email,
      clave: usuario.clave,
      nombre: usuario.nombre,
      telefono: usuario.telefono,
      idioma: usuario.idioma,
      descripcion: usuario.descripcion
    })
  }

  on_submit() {
    const valido = this.comprueba_formulario();
    if (valido) {
      const datos_usuario = this.formatea_inserta_usuario();
      if (this.es_nuevo) {
        this.envia_nuevo_usuario.emit(datos_usuario)
      } else {
        this.envia_actualiza_usuario.emit(datos_usuario);
      }
    }
  }

  comprueba_formulario(): boolean {
    const { email, clave, nombre, telefono, idioma} = this.formGroup.value
    if (email === null || email === undefined || email === '') {
      this._toast.error("El campo email es obligatorio");
      return false;
    }
    if (clave === null || clave === undefined || clave === '') {
      this._toast.error("El campo contraseña es obligatorio");
      return false;
    }
    if (telefono === null || telefono === undefined || telefono === '') {
      this._toast.error("El campo telefono es obligatorio");
      return false;
    }
    if (nombre === null || nombre === undefined || nombre === '') {
      this._toast.error("El campo nombre es obligatorio");
      return false;
    }
    if (idioma === null || idioma === undefined || idioma === '') {
      this._toast.error("El campo email es obligatorio");
      return false;
    }
    return true;
  }

  formatea_inserta_usuario(): IInsertaUsuario {
    return {
      email: this.formGroup.value.email!!,
      clave: this.formGroup.value.clave!!,
      telefono: this.formGroup.value.telefono!!,
      nombre: this.formGroup.value.nombre!!,
      nivel: 99,
      idioma: this.formGroup.value.idioma!!,
      descripcion: this.formGroup.value.descripcion!!
    }
  }
}
