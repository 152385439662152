
<!-- Barra de Navegacion -->
<div class="mt-1">
    <button class="btn-navegador"
      [disabled]="pagina_actual==1"
      (click)="primera_pagina()">
      <fa-icon [icon]="faAnglesLeft" class="text-muted cursor ml-5" ></fa-icon>
    </button>
    <button class="btn-navegador"
      [disabled]="pagina_actual==1"
      (click)="resta_pagina()">
     <fa-icon [icon]="faAngleLeft" class="text-muted cursor ml-1" ></fa-icon>
    </button>
    {{pagina_actual}} de {{total_paginas}}
    <button class="btn-navegador"
      [disabled]="pagina_actual==total_paginas"
      (click)="suma_pagina()">
      <fa-icon [icon]="faAngleRight" class="text-muted cursor ml-5" ></fa-icon>
    </button>
    <button class="btn-navegador"
      [disabled]="pagina_actual==total_paginas"
      (click)="ultima_pagina()">
      <fa-icon [icon]="faAnglesRight" class="text-muted cursor ml-1" ></fa-icon>
    </button>
</div>
