<form class="form-container"  #articulo_form="ngForm" (ngSubmit)="on_submit()">
  <div class="form-header mt-4 d-flex justify-content-between">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de Artículo</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de artículo</h6>
    <a 
    target="_blank" 
    *ngIf="!es_nuevo && articulo !== null" 
    [routerLink]="'/catalogo/'+ articulo.familia_enlace + '/' + articulo.subfamilia_enlace + '/' + articulo.uid" 
    class="btn btn-aceptar">Ver en catálogo</a>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="form-group full-width mb-3">
      <label for="" class="align-bottom">Referencia</label>
      <input
        [class.invalid]="formGroup.controls.referencia.invalid && formGroup.controls.referencia.dirty && formGroup.controls.referencia.touched"
        [formControl]="formGroup.controls.referencia"
        id="referencia"
        name="referencia"
        placeholder="2580"
        required
        step="1"
        type="text"
        class="form-control"
      >
    </div>
    
    <div class="form-group">
      <label for="familia"></label>
      <select
        [class.invalid]="formGroup.controls.familia_uid.invalid && formGroup.controls.familia_uid.dirty && formGroup.controls.familia_uid.touched && formGroup.controls.familia_uid.value == 0"
        [formControl]="formGroup.controls.familia_uid"
        id="familia"
        name="familia"
        (change)="cambia_familia($event)"
        class="form-select">
    
        <option disabled value="0">Selecciona familia</option>
        <option *ngFor="let familia of familias" [value]="familia.uid">
          {{familia.familia}}
        </option>
      </select>
    </div>
    
    
    
    
    <app-select-input
      [label]="'Subfamilia *'"
      [optionsLabels]="['uid', 'subfamilia']"
      [optionData]="subfamilias"
      [defaultSelectText]="'Selecciona subfamilia'"
      [control]="formGroup.controls.subfamilia_uid"
      [name]="'select-tipo-cliente'"
      [id]="'select-tipo-cliente'"
    />
    
    
    <app-select-input
      [label]="'Zona *'"
      [optionsLabels]="['uid', 'zona']"
      [optionData]="zonas"
      [defaultSelectText]="'Selecciona zona'"
      [control]="formGroup.controls.zona_uid"
      [name]="'select-tipo-cliente'"
      [id]="'select-tipo-cliente'"
    />
    
    <app-input
      class="mt-3"
      [control]="formGroup.controls.articulo"
      [label]="'Artículo *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.articulo_en"
      [label]="'Artículo Inglés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.articulo_fr"
      [label]="'Artículo Francés'"
      [type]="'text'"
      [required]="false"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'"/>
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.descripcion"
      [label]="'Descripción *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'" />
    <app-input
      class="full-width mt-3" 
      [control]="formGroup.controls.descripcion_en"
      [label]="'Descripción Inglés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'"/>
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.descripcion_fr"
      [label]="'Descripción Francés'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />
    <app-input
      class="full-width mt-3"
      [control]="formGroup.controls.keywords"
      [label]="'Keywords *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta Madera Grande'"
      [id]="'nombre'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.datos_proveedor"
      [label]="'Datos del proveedor *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'" />
    
    <div class="form-group mt-3">
      <label for="precio-compra" class="align-bottom">Precio de Compra *</label>
      <input
        [class.invalid]="formGroup.controls.precio_compra.invalid && formGroup.controls.precio_compra.dirty && formGroup.controls.precio_compra.touched"
        [formControl]="formGroup.controls.precio_compra"
        id="precio-compra"
        name="precio-compra"
        placeholder="25.50"
        required
        step="0.01"
        type="number"
        min="0" 
        pattern="[0-9]+([.,][0-9]{1,2})?"
        type="number"
        class="form-control"
      >
    </div>
    <div class="form-group mt-3">
      <label for="precio-1" class="align-bottom">Precio 1 *</label>
      <input
        [class.invalid]="formGroup.controls.precio_1.invalid && formGroup.controls.precio_1.dirty && formGroup.controls.precio_1.touched"
        [formControl]="formGroup.controls.precio_1"
        id="precio-1"
        name="precio-1"
        placeholder="25.50"
        required
        step="0.01"
        type="number"
        min="0" 
        pattern="[0-9]+([.,][0-9]{1,2})?"
        type="number"
        class="form-control"
      >
    </div>
    <div class="form-group mt-3">
      <label for="precio-1" class="align-bottom">Precio 2 *</label>
      <input
        [class.invalid]="formGroup.controls.precio_2.invalid && formGroup.controls.precio_2.dirty && formGroup.controls.precio_2.touched"
        [formControl]="formGroup.controls.precio_2"
        id="precio-2"
        name="precio-2"
        placeholder="25.50"
        required
        step="0.01"
        type="number"
        min="0" 
        pattern="[0-9]+([.,][0-9]{1,2})?"
        type="number"
        class="form-control"
      >
    </div>

    <app-input
      class="mt-3"
      [control]="formGroup.controls.stock"
      [label]="'Stock *'"
      [type]="'number'"
      [placeholder]="'Ej: 25'"
      [id]="'stock'">
    </app-input>
    <div></div>
    <div class="mt-3 position-relative">
      <p class="">Imagen Principal</p>
      <img *ngIf="imagen_a_mostrar !== ''" class="img-fluid border" [src]="imagen_a_mostrar">
      <div class="pb-3">
      </div>
      <button (click)="borra_imagen();" *ngIf="formGroup.value.file_name !== '' && formGroup.value.file_name !== null && formGroup.value.file_name !== undefined" class="btn btn-danger position-absolute top-0 end-0">
        <fa-icon [icon]="icono_cerrar" />
      </button>
      <input
        type="file"
        class="form-control"
        style="max-height: 40px;"
        (change)="this.formatear_archivo($event)"
        accept="image/png, image/jpeg, application/pdf"
      />
    </div>
    
  </div>
  <p *ngIf="!es_nuevo" class="mt-5">
    Imagenes secundarias
  </p>
  <input
    *ngIf="!es_nuevo"
    type="file"
    class="form-control"
    (change)="this.formatear_archivos_secundarios($event)"
    accept="image/png, image/jpeg, application/pdf"
    multiple
  />
  <div class="d-flex flex-wrap gap-3">
    <ng-container *ngFor="let item of imagenes_articulo" >
      <div class="position-relative mt-3" style="max-width: 120px;">
        <button (click)="borra_imagen_secundaria(item.uid);" class="btn btn-danger position-absolute top-0 end-0">
          <fa-icon [icon]="icono_cerrar" />
        </button>
        <img class="img-fluid" [src]="item.file_name" alt="">
      </div>
    </ng-container>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      role="button"
      [disabled]="formGroup.invalid"
      type="submit"
      class="btn btn-aceptar mt-5"
      >
      <span  *ngIf="es_nuevo">
        Crear
      </span>
      <span  *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/intranet/articulos'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
