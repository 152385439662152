import { globalState } from "src/global";
import { IArticulos } from "../interface/articulo.interface";

export class ArticuloModel implements IArticulos{
  uid: number;
  idioma: string;
  zona_uid: number;
  familia_uid: number;
  subfamilia_uid: number;
  referencia: string;
  articulo: string;
  articulo_en: string;
  articulo_fr: string;
  descripcion: string;
  descripcion_en: string;
  descripcion_fr: string;
  keywords: string;
  proveedor: string;
  datos_proveedor: string;
  stock: number;
  precio_compra: string;
  precio_1: string;
  precio_2: string;
  file_type: string;
  file_name: string;
  file_size: string;
  fecha_alta: string;
  fecha_modificacion: string | null | null;
  fecha_consulta: string | null;
  usuario_alta: number;
  usuario_modificacion: number;
  usuario_consulta: number;
  consultas: number;
  modificaciones: number;
  familia_enlace: string;
  subfamilia_enlace: string;

  constructor (item: IArticulos) {
    this.uid = item.uid;
    this.idioma = item.idioma;
    this.zona_uid = item.zona_uid;
    this.familia_uid = item.familia_uid;
    this.subfamilia_uid = item.subfamilia_uid;
    this.referencia = item.referencia;
    this.articulo = item.articulo;
    this.articulo_en = item.articulo_en;
    this.articulo_fr = item.articulo_fr;
    this.descripcion = item.descripcion;
    this.descripcion_en = item.descripcion_en;
    this.descripcion_fr = item.descripcion_fr;
    this.keywords = item.keywords;
    this.proveedor = item.proveedor;
    this.datos_proveedor = item.datos_proveedor;
    this.stock = item.stock;
    this.precio_compra = item.precio_compra;
    this.precio_1 = item.precio_1;
    this.precio_2 = item.precio_2;
    this.file_type = item.file_type;
    this.file_name = `${globalState.urlImg}articulos/${item.file_name}` ;
    this.file_size = item.file_size;
    this.fecha_alta = item.fecha_alta;
    this.fecha_modificacion = item.fecha_modificacion;
    this.fecha_consulta = item.fecha_consulta;
    this.usuario_alta = item.usuario_alta;
    this.usuario_modificacion = item.usuario_modificacion;
    this.usuario_consulta = item.usuario_consulta;
    this.consultas = item.consultas;
    this.modificaciones = item.modificaciones;
    this.familia_enlace = item.familia_enlace;
    this.subfamilia_enlace = item.subfamilia_enlace;
  }
}