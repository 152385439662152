<app-page-container-publico [title]="'Catálogo'">
  <app-breadcrumb-container id="breadcrumb" />
  <div class="about-area ptb-100 mt-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 mx-auto">
          <div class="about-content">
            <p class="parrafo">
              En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de
              la Sociedad de la Información y Comercio Electrónico le informamos
              de la utilización de cookies en la web https://diegoreinaldos.com/
            </p>
          </div>
          <div class="about-content">
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <h2 class="m-3">¿QUÉ SON LAS COOKIES?</h2>
            <p class="parrafo">
              Este sitio web utiliza cookies y/o tecnologías similares que
              almacenan y recuperan información cuando navega. Las cookies son
              pequeños archivos que algunas plataformas, como las páginas web,
              pueden instalar en su ordenador, smartphone, tableta o televisión
              conectada, al acceder a las mismas. Sus funciones pueden ser muy
              variadas: almacenar sus preferencias de navegación, recopilar
              información estadística, permitir ciertas funcionalidades
              técnicas, almacenar y recuperar información sobre los hábitos de
              navegación o preferencias de un usuario o de su equipo hasta el
              punto, en ocasiones, dependiendo de la información que contengan y
              de la forma en que utilice su equipo, de poder reconocerlo. Una
              cookie se almacena en un ordenador para personalizar y facilitar
              al máximo la navegación del usuario. Las cookies se asocian
              únicamente a un usuario y su ordenador y no proporcionan
              referencias que permitan deducir datos personales del usuario. El
              usuario podrá configurar su navegador para que notifique o rechace
              la instalación de las cookies enviadas por el sitio web. Las
              cookies son útiles por varios motivos. Desde un punto de vista
              técnico, permiten que las páginas web funcionen de forma más ágil
              y adaptada a las preferencias del usuario como, por ejemplo,
              almacenar su idioma. Además, ayudan a los responsables de los
              sitios web a mejorar los servicios que ofrecen, gracias a la
              información estadística que recogen a través de ellas. Los usos
              concretos que hacemos de estas tecnologías se describen a
              continuación.
            </p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">¿QUÉ TIPOS DE COOKIES USAMOS Y PARA QUÉ?</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">
              <strong>Cookies Necesarias o técnicas:</strong> son necesarias
              para el funcionamiento de la web, almacenan información para hacer
              posible la navegación. También sirven para guardar configuración
              personalizada del usuario en webs dinámicas: color, diseño,
              maquetación, etc. Cookies de seguridad: se utilizan para evitar
              ataques externos contra la web o los usuarios que naveguen en
              ella.
            </p>
            <br />
            <p class="parrafo"><strong>ID</strong> PHPSESSID</p>
            <p class="parrafo"><strong>Duración</strong> Sesión</p>
            <p class="parrafo">
              <strong>Descripción</strong> Esta cookie es nativa de las
              aplicaciones PHP. La cookie almacena e identifica la ID de sesión
              única de un usuario para administrar las sesiones de usuario en el
              sitio web. La cookie es una cookie de sesión y se eliminará cuando
              se cierren todas las ventanas del navegador.
            </p>
            <br />
            <p class="parrafo"><strong>ID</strong> cookieyes-consent</p>
            <p class="parrafo"><strong>Duración</strong> 1 Año</p>
            <p class="parrafo">
              <strong>Descripción</strong> CookieYes establece esta cookie para
              recordar las preferencias de consentimiento de los usuarios para
              que se respeten sus preferencias en visitas posteriores a este
              sitio. No recopila ni almacena ninguna información personal sobre
              los visitantes del sitio.
            </p>
          </div>

          <div class="about-content mt-3">
            <h2 class="m-3">
              Configuración de preferencias y deshabilitación de cookies
            </h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">
              Ud. puede decidir que cookies quiere que se instalen en su
              navegador. Si Ud. guarda su elección sin haber seleccionado
              ninguna cookie, equivaldrá al rechazo de todas las cookies excepto
              las necesarias.
            </p>
            <p class="parrafo">
              El usuario también puede configurar su navegador para aceptar, o
              no, las cookies que recibe o para que el navegador le avise cuando
              un servidor quiera guardar una cookie o borrarlas de su ordenador.
              Los navegadores web son las herramientas encargadas de almacenar
              las cookies y desde este lugar debe efectuar su derecho a
              eliminación o desactivación de las mismas. PEDRO GINER REVERTE no
              puede garantizar la correcta o incorrecta manipulación de las
              cookies por parte de los mencionados navegadores.
            </p>
            <p class="parrafo">
              Puede encontrar las instrucciones en la configuración de seguridad
              en su navegador Web. Ejemplos:
            </p>
            <ul>
              <li>
                <p class="parrafo">
                  Si utiliza Microsoft Internet Explorer, en la opción de menú
                  Herramientas > Opciones de Internet > Privacidad >
                  Configuración.
                </p>
              </li>
              <li>
                <p class="parrafo">
                  Si utiliza Firefox, en la opción de menú Herramientas >
                  Opciones > Privacidad > Cookies.
                </p>
              </li>
              <li>
                <p class="parrafo">
                  Si utiliza Google Chrome, en la opción de menú Configuración >
                  Mostrar opciones avanzadas > Privacidad > Configuración de
                  contenido.
                </p>
              </li>
              <li>
                <p class="parrafo">
                  Si utiliza Safari, en la opción de menú Preferencias >
                  Seguridad.
                </p>
              </li>
            </ul>
            <p class="parrafo">
              Puede hacer uso de la sección Ayuda que encontrará en la barra de
              herramientas de la mayoría de los navegadores para cambiar los
              ajustes de su ordenador, sin embargo, algunas de las
              características de nuestros servicios online pueden no funcionar o
              pueden resultar más complicadas de acceder si rechaza todas las
              cookies. Muchos navegadores permiten activar un modo privado
              mediante el cual las cookies se borran siempre después de su
              visita. Dependiendo de cada navegador, este modo privado puede
              tener diferentes nombres, abajo puede encontrar una lista de los
              navegadores más comunes y los diferentes nombres de este «modo
              privado»:
            </p>
            <p class="parrafo">
              Puede hacer uso de la sección Ayuda que encontrará en la barra de
              herramientas de la mayoría de los navegadores para cambiar los
              ajustes de su ordenador, sin embargo, algunas de las
              características de nuestros servicios online pueden no funcionar o
              pueden resultar más complicadas de acceder si rechaza todas las
              cookies. Muchos navegadores permiten activar un modo privado
              mediante el cual las cookies se borran siempre después de su
              visita. Dependiendo de cada navegador, este modo privado puede
              tener diferentes nombres, abajo puede encontrar una lista de los
              navegadores más comunes y los diferentes nombres de este «modo
              privado»: En cumplimiento de la Ley 34/2002, de 11 de julio, de
              Servicios de la Sociedad de la Información y Comercio Electrónico
              le informamos de la utilización de cookies en la web
              https://diegoreinaldos.com/
            </p>
            <ul>
              <li>
                <p class="parrafo">Internet Explorer 8 y superior: InPrivate</p>
              </li>
              <li>
                <p class="parrafo">
                  FireFox 3.5 y superior: Navegación Privada
                </p>
              </li>
              <li>
                <p class="parrafo">Google Chrome 10 y superior: Incógnito</p>
              </li>
              <li>
                <p class="parrafo">Safari 2 y superior: Navegación Privada</p>
              </li>
              <li>
                <p class="parrafo">Opera 10.5 y superior: Navegación Privada</p>
              </li>
            </ul>
            <p class="parrafo">
              Por favor, lea atentamente la sección de ayuda de su navegador
              para conocer más acerca de cómo activar el «modo privado». Podrá
              seguir visitando nuestra Web aunque su navegador esté en «modo
              privado», no obstante, la experiencia de usuario puede no ser
              óptima y algunas utilidades pueden no funcionar. En el caso de
              bloquear o no aceptar la instalación de cookies es posible que
              ciertos servicios ofrecidos por nuestro sitio web que necesitan su
              uso queden deshabilitados y, por lo tanto, no estén disponibles
              para usted por lo que no podrá aprovechar por completo todo lo que
              nuestra web y aplicaciones le ofrecen.
            </p>
            <p class="parrafo">
              Visita
              <a href="https://aboutcookies.org" target="_blank"
                >https://aboutcookies.org</a
              >
              ó
              <a href="https://cookiedatabase.org/" target="_blank"
                >https://cookiedatabase.org/</a
              >
              para aprender cómo gestionar y borrar las cookies. También puede
              visitar youronlinechoices.eu (europeo)para aprender qué son y cómo
              se gestionan las cookies publicitarias.
            </p>
            <p class="parrafo">
              Es importante tener en cuenta que restringir o desactivar el uso
              de cookies puede limitar la funcionalidad de los sitios, o evitar
              que funcionen correctamente.
            </p>
            <p class="parrafo">
              <strong>Aceptación de Cookies.</strong> Usted acepta la
              utilización de cookies para navegar por nuestra web después de
              haberle informado sobre nuestra Política de cookies. Al acceder a
              este sitio web o aplicación por primera vez, verá una ventana o
              banner donde se le informa de la utilización de las cookies y
              donde puede consultar esta política de cookies. Si usted consiente
              la utilización de cookies, éstas se instalarán en su equipo o
              dispositivo. Además, del uso de nuestras cookies propias,
              permitimos a terceros establecer cookies y acceder a ellas en su
              ordenador. Para cualquier consulta sobre el uso de cookies en
              nuestro sitio web puede dirigirse al email: info@diegoreinaldos.es
            </p>
            <p class="parrafo">
              <strong
                >Modificación de las condiciones de uso NURIA REINALDOS Y OTRA,
                C.B.</strong
              >
              se reserva el derecho de desarrollar, modificar o actualizar en
              cualquier momento las condiciones de uso de la presente página web
              por lo que el usuario deberá leerlas periódicamente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-page-container-publico>
