<div *ngIf="total_paginas <= 8"  class="paginacion mt-1">
  <button
    *ngFor="let pagina of [].constructor(total_paginas); let i = index"
    [ngClass]="{ 'selected': pagina_actual == i +1, 'btn-navegacion': true }"
    (click)="ir_a_pagina(i+1)"
  >
  {{ i + 1}}
  </button>
</div>

<div *ngIf="total_paginas > 8" class="paginacion mt-1">
  <!--<button
  [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
  (click)="ir_a_pagina(1)">
  <<
</button> -->
<button
  [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
  (click)="ir_a_pagina(pagina_actual - 1)">
  <
</button>
      <button
        [ngClass]="{ 'selected': pagina_actual == 1, 'btn-navegacion': true }"
        (click)="ir_a_pagina(1)"
      >
      {{ 1 }}
      </button>
    
      <button
        [ngClass]="{ 'selected': pagina_actual == 2, 'btn-navegacion': true }"
        (click)="ir_a_pagina(2)"
      >
      {{ 2 }}
      </button>
      <span *ngIf="pagina_actual > 2"> ... </span>
      <button *ngIf="pagina_actual > 2 && pagina_actual < total_paginas -1"
        [ngClass]="{ 'selected': true, 'btn-navegacion': true }"
        (click)="ir_a_pagina(pagina_actual)">
        {{ pagina_actual }}
      </button>
      <span *ngIf="pagina_actual < total_paginas - 1"> ... </span>
      <button
        [ngClass]="{ 'selected': pagina_actual == total_paginas - 1, 'btn-navegacion': true }"
        (click)="ir_a_pagina(total_paginas - 1)">
        {{ total_paginas - 1 }}
      </button>
      <button
        [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
        (click)="ir_a_pagina(total_paginas)">
        {{ total_paginas }}
      </button>
      <button
        [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
        (click)="ir_a_pagina_siguiente()">
        >
      </button>
      <!-- <button
        [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
        (click)="ir_a_pagina(total_paginas)">
        >>
      </button> -->
</div>
