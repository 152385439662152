<app-page-container-publico [title]="'Catálogo'">
  <app-breadcrumb-container id="breadcrumb" />
  <div class="about-area ptb-100 mt-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 mx-auto">
          <div class="about-content">
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">NURIA REINALDOS Y OTRA, C.B., en adelante
              “empresa”, puede recoger y tratar datos de los usuarios del sitio
              web, clientes, proveedores, colaboradores y otros interesados
              habiendo sido obtenidos por diferentes medios como:</p>
            <ul>
              <li>
                <p class="parrafo">Formularios web</p>
              </li>
              <li>
                <p class="parrafo">Correo electrónico</p>
              </li>
              <li>
                <p class="parrafo">Contacto telefónico</p>
              </li>
              <li>
                <p class="parrafo">Formularios en papel</p>
              </li>
              <li>
                <p class="parrafo">Correo postal</p>
              </li>
              <li>
                <p class="parrafo">Fuentes de acceso público (diarios y
                  boletines oficiales, medios de comunicación, etc)</p>
              </li>
              <li>
                <p class="parrafo">Contratos mercantiles o comerciales</p>
              </li>
            </ul>
            <p class="parrafo">El tratamiento de los datos recabados a través de
              dichas fuentes se realizarán según lo dispuesto en el reglamento
              UE 2016/679 General de Protección de Datos, y demás normativa
              vigente al respecto.</p>
            <p class="parrafo">A efectos de presentar la política de privacidad
              de la empresa se exponen los siguientes epígrafes:</p>
            <p class="parrafo">¿Quién es el Responsable de Tratamiento de sus
              datos?</p>
            <ul>
              <li>
                <strong>NOMBRE: </strong> NURIA REINALDOS Y OTRA, C.B.
              </li>
              <li>
                <strong>CIF: </strong> E30454441
              </li>
              <li>
                <strong>DOMICILIO: </strong> CAMINO CENTRAL, PANEL 55 - Nº 128 -
                Lorca
              </li>
              <li>
                <strong>TELÉFONO: </strong> 968 40 15 33
              </li>
              <li>
                <strong>EMAIL: </strong> info@diegoreinaldos.com
              </li>
            </ul>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS
              PERSONALES?</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">El sitio web y sus servicios son de acceso libre,
              no obstante, la empresa condiciona la utilización de algunos de
              los servicios ofrecidos en su web a la previa cumplimentación del
              correspondiente formulario. El usuario garantiza la autenticidad y
              actualidad de todos aquellos datos que comunique a la empresa y
              será el único responsable de manifestaciones inexactas o falsas
              que realice. El usuario se compromete expresamente a hacer un uso
              adecuado de los contenidos y servicios de la empresa y a no
              emplearlos para, entre otros:</p>
            <ul>
              <li>
                <p class="parrafo">En la empresa tratamos los datos de las
                  personas interesadas para la gestión y desarrollo de las
                  comunicaciones (contacto de usuarios, resolución de consultas,
                  promoción comercial, envío de boletines, gestión de perfiles
                  de la empresa en redes sociales, etc.) así como para atender
                  los servicios solicitados y desarrollar nuestra actividad de
                  acuerdo con nuestro objeto social y estatutos, así como en
                  cumplimiento de cualquier contrato mercantil o comercial que
                  implique necesariamente el tratamiento de datos para su
                  cumplimiento.</p>
              </li>
              <li>
                <p class="parrafo">En base a la información facilitada
                  elaboraremos un perfil comercial con el objetivo de mejorar su
                  experiencia de usuario y personalizar las ofertas y
                  comunicaciones que consideremos de su interés. No se tomarán
                  decisiones individualizadas automatizadas en base a dicho
                  perfil.</p>
              </li>
              <li>
                <p class="parrafo">Si el motivo de contactar con la empresa y
                  enviar información sobre su persona tiene como objetivo la
                  búsqueda de empleo (envío de currículums, etc.). Los datos
                  facilitados serán tratados para evaluar su posible candidatura
                  a ofertas de empleo de la empresa. Si los datos facilitados
                  resultan de interés para la empresa, se elaborará un perfil
                  con estos quedando archivados para la gestión de recursos
                  humanos.</p>
              </li>
            </ul>

          </div>

          <div class="about-content mt-3">
            <p class="parrafo">¿QUÉ TIPO DE DATOS TRATAMOS?</p>
            <p class="parrafo">Además de los diferentes medios de obtención
              citados, así como las diferentes finalidades de tratamiento
              expuestas, le informamos que los tipos de datos que podemos tratar
              en nuestro sistema de información son:</p>
            <ul>
              <li>
                <p class="parrafo">Datos identificativos</p>
              </li>
              <li>
                <p class="parrafo">Códigos o claves de identificación</p>
              </li>
              <li>
                <p class="parrafo">Direcciones postales o electrónicas</p>
              </li>
              <li>
                <p class="parrafo">Datos de característica personales y
                  profesionales</p>
              </li>
              <li>
                <p class="parrafo">Datos económicos y financieros</p>
              </li>
            </ul>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">Los datos personales se conservarán mientras se
              mantenga relación con usted, bien como cliente, o bien como otro
              tipo de interesado; mientras no se solicite la supresión de estos;
              o mientras exista alguna previsión o exigencia legal de
              conservación de estos.</p>
            <p class="parrafo">Cuando los datos dejen de ser necesarios para las
              finalidades por las que fueron recabados serán suprimidos
              asegurando la confidencialidad de estos.</p>
            <p class="parrafo">En el caso de datos facilitados con motivo de
              búsqueda de empleo, si estos no resultasen de interés para la
              empresa, serán eliminados inmediatamente asegurando su
              confidencialidad.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS
              DATOS?</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">La base legal para el tratamiento de sus datos
              puede deberse a la ejecución de algún contrato comercial,
              mercantil, laboral, etc., en el que el interesado sea parte. Otra
              base legal para el tratamiento de sus datos es el consentimiento
              informado en el caso de otros tratamientos como, por ejemplo:
              formulario de contacto del sitio web, inscripción a actividades
              desarrolladas por la empresa, participación en los perfiles de
              redes sociales de la empresa, y cualquier otro tratamiento que
              implique necesariamente la recogida de sus datos personales, para
              lo cual será requisito imprescindible una clara acción afirmativa
              por parte del interesado.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS?</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">Los datos de los interesados no serán comunicados
              a ningún tercero, salvo obligación legal al respecto.</p>
          </div>
          <div class="about-content mt-3">
            <p class="parrafo">¿Cuáles son sus derechos cuando nos facilita y/o
              tratamos sus datos?</p>
            <p class="parrafo">Como interesado, usted podrá en cualquier momento
              solicitarnos el ejercicio de cualquiera de los siguientes derechos
              que le asisten en materia de protección de datos:</p>
            <ul>
              <li>
                <p class="parrafo">Acceso a los datos personales del interesado
                  para confirmar si se están tratando o no datos que le
                  conciernan y obtener más información acerca de este
                  tratamiento.</p>
              </li>
              <li>
                <p class="parrafo">Rectificación o Supresión de los datos
                  personales que conciernan al interesado cuando, entre otros
                  motivos, sean inexactos o ya no sean necesarios para las
                  finalidades por las que fueron recogidos.</p>
              </li>
              <li>
                <p class="parrafo">Limitar el tratamiento de los datos
                  personales del interesado en determinadas circunstancias, en
                  cuyo caso únicamente se conservarán a efectos de ejercicio o
                  defensa de reclamaciones, por la protección de los derechos de
                  otra persona o por razones de interés público.</p>
              </li>
              <li>
                <p class="parrafo">Recibir los datos personales que le incumban,
                  que previamente nos haya facilitado, y en formato estructurado
                  cuando sea posible. (Portabilidad de sus datos).</p>
              </li>
              <li>
                <p class="parrafo">Oponerse al tratamiento de sus datos en
                  determinadas circunstancias y por motivos relacionados con su
                  situación particular. La empresa dejará de tratar sus datos,
                  salvo por motivos legítimos imperiosos, o el ejercicio o la
                  defensa de posibles reclamaciones.</p>
              </li>
            </ul>
            <p class="parrafo">Para ello sólo tendrá que contactar con nosotros
              a través de la dirección email info@diegoreinaldos.com, o bien
              mediante un escrito postal a CAMINO CENTRAL, PANEL 55 - Nº 128,
              Lorca, Murcia</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">SEGURIDAD DE LOS DATOS</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">La empresa adopta en su sistema de información
              las medidas técnicas y organizativas necesarias para garantizar un
              adecuado nivel de confidencialidad, integridad y disponibilidad de
              la información que tratamos.</p>
            <p class="parrafo">No obstante, hasta donde el ordenamiento jurídico
              lo permite no asumimos ninguna responsabilidad por los daños y
              perjuicios de alteraciones que terceros pueden causar en nuestro
              sistema de información. Cualquier quiebra de seguridad será
              convenientemente y de forma inmediata comunicada a la autoridad
              competente y/o fuerzas y cuerpos de seguridad del Estado.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">ENVÍO DE COMUNICACIONES O INFORMACIÓN</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">Nuestra política con respecto al envío
              información a través de medios telemáticos (correo electrónico,
              mensajería instantánea, etc.), se limita a enviar únicamente
              comunicaciones que consideremos de interés para nuestros usuarios
              e interesados, en relación con las funciones y actividad de la
              empresa, o que usted haya consentido recibir.</p>
            <p class="parrafo">Si prefiere no recibir estos mensajes, le
              ofreceremos a través de estos la posibilidad de ejercer su derecho
              de cancelación y renuncia a la recepción de estos mensajes, en
              conformidad con lo dispuesto en el Título III, artículo 22 de la
              Ley 34/2002 de Servicios para la Sociedad de la Información y de
              Comercio Electrónico.</p>
            <p class="parrafo">La presente política de privacidad ha sido
              revisada en Julio de 2023, por lo que pueden existir variaciones
              hasta la próxima revisión del texto.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</app-page-container-publico>