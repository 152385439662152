import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item-table-number-name',
  templateUrl: './item-table-number-name.component.html',
  styleUrls: ['./item-table-number-name.component.scss']
})
export class ItemTableNumberNameComponent {
  // Valor a comparar en la tabla.
  @Input() value!: any;
  // Nombre a mostrar en la tabla.
  @Input() labels!: string[];
}
