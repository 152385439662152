import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-cookies-page',
  templateUrl: './politica-cookies-page.component.html',
  styleUrls: ['./politica-cookies-page.component.scss']
})
export class PoliticaCookiesPageComponent {

}
