import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faWhatsapp, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ProductosTipoUnoModel } from 'src/app/models/entity/productos_tipo_uno_model';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { globalState, urlBase } from 'src/global';

@Component({
  selector: 'app-producto-content-tipo-uno',
  templateUrl: './producto-content-tipo-uno.component.html',
  styleUrls: ['./producto-content-tipo-uno.component.scss']
})
export class ProductoContentTipoUnoComponent {
  @Input() producto!: ProductosTipoUnoModel;
  @Input() ver_boton_compartir: boolean = false;
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  url: string;
  mensaje_whatsapp: string = "";
  enlace_whatsapp: string = "";
  icono_whatsapp: IconDefinition = faWhatsapp
  constructor(
    private _router: Router
  ) {
    this.url = _router.url;
  }

  ngOnInit() {
    this.mensaje_whatsapp = `¡Hola! \n\n He encontrado este/a: ${this.producto.titulo} en: ${urlBase}${this.url}`;
    this.enlace_whatsapp = `https://wa.me/?text=${encodeURIComponent(this.mensaje_whatsapp)}`;
  }

}
