<div *ngIf="item.enlace === null" class="card" style="padding: 30px">
  <div class="icon">
    <fa-icon [icon]="item.icono" />
  </div>
  <b>{{ item.titulo }}</b>
  <p>{{ item.descripcion }}</p>
</div>

<div *ngIf="item.enlace !== null" class="card">
  <a [href]="item.enlace">
    <div class="icon">
      <fa-icon [icon]="item.icono" />
    </div>
    <b>{{ item.titulo }}</b>
    <p style="white-space: pre-wrap;">{{ item.descripcion }}</p>
  </a>
</div>