import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { deleteToken } from 'src/app/services/helpers/session.helper';
import { AuthService } from 'src/app/services/https/auth.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-intranet-container',
  templateUrl: './intranet-container.component.html',
  styleUrls: ['./intranet-container.component.scss'],
  providers: [AuthService]
})
export class IntranetContainerComponent {

  constructor (
    private _router: Router,
    private _auth_service: AuthService
  ) {

  }

  async ngOnInit(): Promise<void> {
    try {
      const token_valido = await this._auth_service.comprueba_token()
      if (!token_valido) {
        // deleteToken();
        // this._router.navigate(['/'])
      }
    } catch (e: any) {
      if (e === "Token caducado") {
        localStorage.removeItem(globalState.tokenName);
        sessionStorage.removeItem(globalState.tokenName)
        globalState.token = undefined;
        this._router.navigate(["/"]);
      }
    }
  }
}

