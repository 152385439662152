export class GridCardsTipoUnoModel {
  id: number;
  titulo: string;
  referencia: string | null;
  imagen: string | null;
  descripcion: string;
  enlace: string | null;
  precio: number | null;
  stock: number | null
  
  constructor(
    id: number,
    titulo: string,
    referencia: string | null,
    imagen: string | null,
    descripcion: string,
    enlace: string | null,
    precio: number | null,
    stock: number | null
  ) {
    this.id = id;
    this.titulo = titulo;
    this.referencia = referencia;
    this.imagen = imagen;
    this.descripcion = descripcion;
    this.enlace = enlace;
    this.precio = precio;
    this.stock = stock;
  }
}