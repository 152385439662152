import { Component, Input } from '@angular/core';
import { GridCardsTipoUnoModel } from 'src/app/models/entity/grid_tipo_uno.model';
import { globalState } from 'src/global';

@Component({
  selector: 'app-grid-cards-tipo-uno',
  templateUrl: './grid-cards-tipo-uno.component.html',
  styleUrls: ['./grid-cards-tipo-uno.component.scss']
})
export class GridCardsTipoUnoComponent {
  @Input() data!: GridCardsTipoUnoModel[];
  es_admin: boolean;
  imagen_url: string = globalState.urlImg;

  constructor() {
    if(globalState.identity) {
      console.log(globalState.identity);

      if (globalState.identity.nivel === 99) {
        this.es_admin = true;
      } else {
        this.es_admin = false;
      }
    } else {
      this.es_admin = false
    }
  }

  ngOnInit() {
  }
}
