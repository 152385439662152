import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare var dataLayer: any; // Declara dataLayer para GTM

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  constructor(private router: Router) {}

  public pushPageView(url: string): void {
    dataLayer = dataLayer || [];
    dataLayer.push({
      'event': 'page_view',
      'page_path': url
    });
  }

  public initTracking(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pushPageView(event.urlAfterRedirects);
      }
    });
  }
}
