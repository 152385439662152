<app-page-container-publico [title]="'Catálogo'">
    <app-breadcrumb-container id="breadcrumb"/>

    <div *ngIf="data.length > 0" class="container">
        <div *ngIf="tipo_catalogo === 1 || tipo_catalogo === 2" class="categorias-mobile pt-5">
            <div class="d-block">
                <select class="form-select" [ngModel]="valor_navegacion" (change)="onCategoriaSeleccionada($event.target)">
                    <ng-container *ngFor="let item of familias">
                        <!-- Opción para la categoría principal -->
                        <option [selected]="valor_navegacion === item.familia_enlace" [value]="item.familia_enlace">{{ item.familia }}</option>

                        <!-- Opción para las subcategorías -->
                        <ng-container *ngFor="let sub of subfamilias">
                            <option *ngIf="sub.familia_uid === item.uid" [value]="item.familia_enlace + '/' +sub.subfamilia_enlace" [selected]="valor_navegacion === item.familia_enlace + '/' +sub.subfamilia_enlace">
                                - {{ sub.subfamilia }}
                            </option>
                        </ng-container>
                    </ng-container>
                </select>
            </div>
        </div>
        <form class="d-flex justify-content-end mt-5" (ngSubmit)="buscar_articulos()">
            <div class="busqueda position-relative" style="max-width: 250px;">
                <input
                        [formControl]="form.controls.valor_busqueda"
                        type="text"
                        class="form-control mr-sm-2 "
                        name="busqueda"
                        placeholder=" Buscar..."
                />
                <div class="position-absolute top-0 end-0 h-100">
                    <button class="btn-buscar" type="submit">
                        <fa-icon [icon]="icono_buscar"/>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex container">
        <div *ngIf="tipo_catalogo === 1 || tipo_catalogo === 2" class="categorias pt-5">
            <p style="font-size: 18px">Categorías:</p>
            <ul class="list-group">
                <li
                        class="list-group-item categoria"
                        *ngFor="let item of familias"

                >
                    <div class="d-flex justify-content-between align-items-center">
                        <a [routerLink]="'/catalogo/'+item.familia_enlace">{{ item.familia }}</a>
                        <fa-icon (click)="toggleSubfamilias(item.uid)" [icon]="icono_abrir"></fa-icon>
                    </div>

                    <!-- Subfamilias desplegables (deben estar justo debajo) -->
                    <ul *ngIf="estadoSubfamilias[item.uid]" class="subfamilias">
                        <li *ngFor="let sub of subfamilias" [hidden]="sub.familia_uid !== item.uid">
                            <a [routerLink]="'/catalogo/'+item.familia_enlace + '/'+sub.subfamilia_enlace">{{ sub.subfamilia }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>



        <div class="main-bar">
            <!-- Tipo familia-->
            <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 0"
                                     [data]="data"
            />

            <!-- Tipo subfamilia-->
            <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 1"
                                     [data]="data"
            />

            <!-- Tipo productos -->
            <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 2"
                                     [data]="data"
            />

            <!-- Tipo busqueda -->
            <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 3"
                                     [data]="data"
            />

            <!-- Barra de Navegacion -->
            <div class="d-flex justify-content-center pb-5">
                <app-paginacion-tipo-dos *ngIf="tiene_paginacion && data.length > 0"
                                         class=""
                                         [pagina_actual]="pagina_actual"
                                         [total_paginas]="total_paginas"
                                         (cambia_pagina)="recibe_pagina($event)"
                />
            </div>
        </div>
    </div>


</app-page-container-publico>