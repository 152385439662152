import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { IActualizaSubfamilia, IInsertaSubfamilia } from 'src/app/models/interface/subfamilia.interface';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';
import { globalState } from 'src/global';

@Component({
  selector: 'app-intranet-subfamilias-form',
  templateUrl: './intranet-subfamilias-form.component.html',
  styleUrls: ['./intranet-subfamilias-form.component.scss']
})
export class IntranetSubfamiliasFormComponent {
  @Input() familias!: FamiliaModel[];
  @Input() id!: number;
  @Input() es_nuevo!: boolean;
  @Input() subfamilia!: SubfamiliaModel | null
  @Output() envia_nueva_subfamilia: EventEmitter<IInsertaSubfamilia> =new EventEmitter<IInsertaSubfamilia>()
  @Output() envia_actualiza_subfamilia: EventEmitter<IActualizaSubfamilia> =new EventEmitter<IActualizaSubfamilia>()
  
  imagen_para_form_data: File | null = null;
  imagen_a_mostrar: string = "";
  enlace: string = "";

  formGroup = new FormGroup({
    subfamilia_enlace: new FormControl<string>('', [Validators.required]),
    idioma: new FormControl<string>('es', [Validators.required]),
    subfamilia: new FormControl<string>('', [Validators.required]),
    subfamilia_en: new FormControl<string>(''),
    subfamilia_fr: new FormControl<string>(''),
    familia_uid: new FormControl<number>(0, [Validators.required]),
    descripcion: new FormControl<string>(''),
    descripcion_en: new FormControl<string>(''),
    descripcion_fr: new FormControl<string>(''),
    file_name: new FormControl<string>('',),
  });

  icono_cerrar: IconDefinition = faClose

  constructor( private _toast: ToastrService){}

  ngOnInit() {
    if (!this.es_nuevo && this.subfamilia) {
      this.rellenar_formulario(this.subfamilia);
      const familia = this.familias.find((f) => f.uid == this.subfamilia!!.uid);
      this.enlace = familia?.familia_enlace || '';
    }
  }

  rellenar_formulario(subfamilia: SubfamiliaModel) {
    this.formGroup.patchValue({
      subfamilia_enlace: subfamilia.subfamilia_enlace,
      subfamilia: subfamilia.subfamilia,
      subfamilia_en: subfamilia.subfamilia_en,
      subfamilia_fr: subfamilia.subfamilia_fr,
      familia_uid: subfamilia.familia_uid,
      descripcion: subfamilia.descripcion,
      descripcion_en: subfamilia.descripcion_en,
      descripcion_fr: subfamilia.descripcion_fr,
      file_name: subfamilia.file_name,
    });
    this.imagen_a_mostrar = subfamilia.file_name;
  }

  formatear_archivo(evento: any): void {
    const upload_event = evento.target;
    if (upload_event.files.length > 0) {
      // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
      const archivo: File = upload_event.files[0];
      const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();

      // Genera un nombre aleatorio para el archivo
      const filename = `${generateRandomName()}.${extension}`;
      const archivo_modificado = new File([archivo], filename, { type: archivo.type })
      this.formGroup.patchValue({ file_name: filename });
      // Asigna el archivo formateado a la propiedad imagen_articulo

      this.imagen_para_form_data = archivo_modificado;

      // Verifica si es una imagen o un PDF para mostrar la vista previa
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        // Si es una imagen, genera una URL temporal para mostrar la vista previa
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagen_a_mostrar = e.target.result

        };
        reader.readAsDataURL(archivo_modificado); // Lee el archivo como Data URL
      } else if (extension === 'pdf') {
        // Si es un PDF, también generamos una URL temporal
        const pdfUrl = URL.createObjectURL(archivo_modificado);
        this.formGroup.patchValue({
          file_name: pdfUrl // Establece la URL del PDF en el formulario
        });
      }
    }
  }

  formatear_para_enlace(evento: any) {
    if (this.es_nuevo) {
      const texto = evento.target.value;
      // Eliminar acentos y caracteres especiales
      const texto_normalizado = texto
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Eliminar acentos
        .replace(/[^a-z0-9\s-]/g, '') // Eliminar signos de exclamación y caracteres especiales
        .replace(/\s+/g, '-') // Reemplazar espacios por guiones
        .replace(/ñ/g, 'n'); // Reemplazar 'ñ' por 'n'

      this.formGroup.patchValue({
        subfamilia_enlace: texto_normalizado
      });
    }
  }

  borra_imagen() {
    this.imagen_a_mostrar = '';
    this.imagen_para_form_data = null;
    this.formGroup.patchValue({ file_name: ''})
  }

  on_submit(){
    const valido = this.comprueba_formulario()
    if (valido) {
      if (this.es_nuevo) { 
        const inserta = this.formatea_inserta_subfamilia();
        this.envia_nueva_subfamilia.emit(inserta);
      } else {
        const actualiza = this.formatea_actualiza_subfamilia();
        this.envia_actualiza_subfamilia.emit(actualiza);
      }
    }
  }

  comprueba_formulario(): boolean {
    const { subfamilia, subfamilia_enlace, familia_uid, file_name } = this.formGroup.value;
    if (subfamilia === null || subfamilia === undefined || subfamilia === '') {
      this._toast.error('Campo subfamilia requerido')
      return false;
    }
    if ((subfamilia_enlace === null ||subfamilia_enlace === undefined || subfamilia_enlace === '') && this.es_nuevo) {
      this._toast.error('Campo enlace requerido')
      return false;
    }
    if (familia_uid === null ||familia_uid === undefined || familia_uid === 0) {
      this._toast.error('Campo familia requerido')
      return false;
    }

    if (file_name === null ||file_name === undefined || file_name === '') {
      this._toast.error('Campo Imagen requerido')
      return false;
    }

    return true;
  }

  formatea_inserta_subfamilia(): IInsertaSubfamilia {
    const { subfamilia, subfamilia_enlace, subfamilia_en, subfamilia_fr, familia_uid, file_name, descripcion, descripcion_en, descripcion_fr } = this.formGroup.value;
    const inserta : IInsertaSubfamilia = {
      subfamilia: subfamilia!!,
      subfamilia_enlace: subfamilia_enlace!!,
      subfamilia_en: subfamilia_en!!,
      subfamilia_fr: subfamilia_fr!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      familia_uid: familia_uid!!,
      archivo: this.imagen_para_form_data!!,
      file_name: file_name!!,
      usuario_alta: globalState.identity?.uid || 0
    };

    return inserta;
  }

  formatea_actualiza_subfamilia(): IActualizaSubfamilia {
    const { subfamilia, subfamilia_enlace, subfamilia_en, subfamilia_fr, familia_uid, file_name, descripcion, descripcion_en, descripcion_fr } = this.formGroup.value;
    const actualiza : IActualizaSubfamilia = {
      uid: this.id,
      subfamilia: subfamilia!!,
      subfamilia_enlace: subfamilia_enlace!!,
      subfamilia_en: subfamilia_en!!,
      subfamilia_fr: subfamilia_fr!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      familia_uid: familia_uid!!,
      archivo: this.imagen_para_form_data || undefined,
      file_name: file_name!!,
      usuario_alta: globalState.identity?.uid || 0
    };

    return actualiza;
  }
}
