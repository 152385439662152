import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent {
  showBanner = false;
  analyticsConsent = false;
  youtubeConsent = false;

  constructor(private cookieService: CookieService) {
    // Mostrar banner si no se ha dado consentimiento
    this.showBanner = !this.cookieService.check('cookie-consent');
  }

  // Aceptar solo las cookies seleccionadas por el usuario
  acceptSelectedCookies() {
    this.cookieService.set('cookie-consent', 'true', 365);
    
    // Guardar el consentimiento por categoría
    if (this.analyticsConsent) {
      this.cookieService.set('analytics-consent', 'true', 365);
      this.loadGoogleAnalytics();
    } else {
      this.cookieService.delete('analytics-consent');
    }

    if (this.youtubeConsent) {
      this.cookieService.set('youtube-consent', 'true', 365);
      this.loadYouTubeEmbed();
    } else {
      this.cookieService.delete('youtube-consent');
    }

    this.showBanner = false;
  }

  // Rechazar todas las cookies no esenciales
  rejectCookies() {
    this.cookieService.set('cookie-consent', 'false', 365);
    this.cookieService.delete('analytics-consent');
    this.cookieService.delete('youtube-consent');
    this.showBanner = false;
  }

  loadGoogleAnalytics() {
    // Implementación para Google Analytics si se acepta
  }

  loadYouTubeEmbed() {
    // Implementación para YouTube si se acepta
  }
}