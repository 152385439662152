import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ArticuloImagenModel } from 'src/app/models/entity/articulo_imagen.model';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { ProductosTipoUnoModel } from 'src/app/models/entity/productos_tipo_uno_model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ArticuloServices } from 'src/app/services/https/articulos.services';
import { ArticulosImagenesServices } from 'src/app/services/https/articulos_imagenes.services';

@Component({
  selector: 'app-producto-page',
  templateUrl: './producto-page.component.html',
  styleUrls: ['./producto-page.component.scss'],
  providers: [ArticuloServices, ArticulosImagenesServices]
})
export class ProductoPageComponent {
  id_producto: number;
  articulo: ArticuloModel | null = null;
  producto_tipo_uno: ProductosTipoUnoModel | null = null;
  imagenes: ArticuloImagenModel[] = [];
  constructor(
    private _articulo_services: ArticuloServices,
    private _img_services: ArticulosImagenesServices,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _router: Router
  ) {
    const urlSegments = this._router.url.split("/").filter(segment => segment !== "");
    this.id_producto = Number(urlSegments[urlSegments.length - 1]);
  }

  async ngOnInit(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      const data = await this._articulo_services.ficha(this.id_producto);
      const db_img = await this._img_services.ficha_articulo(this.id_producto);

      const imagenes: string[] = [data.file_name];
      this.articulo = data;
      db_img.forEach((item: ArticuloImagenModel) => {
        imagenes.push(item.file_name);
      })
      this.producto_tipo_uno = new ProductosTipoUnoModel(
        data.articulo,
        data.descripcion,
        null,
        data.referencia,
        imagenes,
      )
    } catch (e: any) {
      this._toast.error(e);
    } finally {
     
      this._loader.notifyLoadChange(false);
    }
  }
}
