export class ProductosTipoUnoModel {
  titulo: string;
  descripcion: string;
  precio: number | null;
  referencia: string | null;
  imagenes: string [];

  constructor(
    titulo: string,
    descripcion:string,
    precio: number | null,
    referencia: string | null,
    imagenes: string[]
  ){
    this.titulo = titulo;
    this.descripcion = descripcion;
    this.precio = precio;
    this.referencia = referencia;
    this.imagenes = imagenes;
  }
}