<ul>
  <li [ngClass]="{ 'select' : opcion === 'articulos'}">
    <a [routerLink]="'/intranet/articulos'"> Articulos </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'familias'}">
    <a [routerLink]="'/intranet/familias'"> Familias </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'subfamilias'}">
    <a [routerLink]="'/intranet/subfamilias'"> Subfamilias </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'novedades'}" >
    <a [routerLink]="'/intranet/novedades'"> Novedades </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'slider'}">
    <a [routerLink]="'/intranet/slider'"> Slider </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'usuarios'}">
    <a [routerLink]="'/intranet/usuarios'"> Usuario </a>
  </li>
  <li [ngClass]="{ 'select' : opcion === 'zonas'}">
    <a [routerLink]="'/intranet/zonas'"> Zonas </a>
  <li [ngClass]="{ 'select' : opcion === 'configuracion'}">
    <a [routerLink]="'/intranet/configuracion'"> Configuración </a>
  </li>
  <button class="bottom__side" (click)="cerrar_sesion()">
    Salir
  </button>
</ul>
