import { globalState } from "src/global";
import { INovedad } from "../interface/novedad.interface";

export class NovedadModel implements INovedad {
  uid: number;
  idioma: string;
  descripcion: string;
  novedad: string;
  keywords: string;
  file_name: string;

  constructor(
    uid: number,
    idioma: string,
    descripcion: string,
    novedad: string,
    keywords: string,
    file_name: string
  ) {
    this.uid = uid;
    this.idioma = idioma;
    this.descripcion = descripcion;
    this.novedad = novedad;
    this.keywords = keywords;
    this.file_name = `${globalState.urlImg}novedades/${file_name}`;
  }
}