<app-intranet-container>
  <form class="form-container"  #articulo_form="ngForm" (ngSubmit)="on_submit()">
    <div class="form-header mt-4 d-flex justify-content-between">
      <h6 class="mb-0">Actualización de configuración</h6>
    </div>
    <div class="form-body-three-cols mt-3">
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario1"
        [label]="'Horario Mañana *'"
        [type]="'text'"
        [placeholder]="'Ej: De 8:00 a...'"
        [id]="'nombre'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario1_en"
        [label]="'Horario Mañana Inglés *'"
        [type]="'text'"
        [placeholder]="'Ej: De 8:00 a...'"
        [id]="'nombre'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario1_fr"
        [label]="'Horario Mañana Francés*'"
        [type]="'text'"
        [placeholder]="'Ej: De 8:00 a...'"
        [id]="'nombre'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario2"
        [label]="'Horario Tarde'"
        [type]="'text'"
        [placeholder]="'Ej: De 16:00 a...'"
        [required]="false"
        [id]="'horario2'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario2_en"
        [label]="'Horario Tarde Inglés'"
        [type]="'text'"
        [placeholder]="'Ej: De 16:00 a...'"
        [required]="false"
        [id]="'horario2'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.horario2_fr"
        [label]="'Horario Tarde Francés'"
        [type]="'text'"
        [placeholder]="'Ej: De 16:00 a...'"
        [required]="false"
        [id]="'horario2'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.telefono"
        [label]="'Teléfono'"
        [type]="'text'"
        [placeholder]="'Ej: 666555444'"
        [required]="false"
        [id]="'horario2'" />
      <app-input
        class="mt-3"
        [control]="formGroup.controls.telefono_whatsapp"
        [label]="'Teléfono Whatsapp'"
        [type]="'text'"
        [placeholder]="'Ej: 666555444'"
        [required]="false"
        [id]="'horario2'" />
    </div>
    <div class="d-flex gap-3 justify-content-start">
      <button
        role="button"
        [disabled]="formGroup.invalid"
        type="submit"
        class="btn btn-aceptar mt-5"
        >
        <span>
          Actualiza
        </span>
      </button>
      <a [routerLink]="'/intranet/articulos'" class="btn btn-outline-error mt-5" >
        Cancelar
      </a>
    </div>
  </form>  
</app-intranet-container>
