import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { IActualizaSlider, IInsertaSlider } from 'src/app/models/interface/slider.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';
import { SliderServices } from 'src/app/services/https/slider.services';

@Component({
  selector: 'app-intranet-slider-form',
  templateUrl: './intranet-slider-form.component.html',
  styleUrls: ['./intranet-slider-form.component.scss']
})
export class IntranetSliderFormComponent {
  @Input() es_nuevo!: boolean;
  @Input() id!: number;
  @Input() slider!: ModelSlider | null;
  @Output() envia_nuevo_slider: EventEmitter<IInsertaSlider> = new EventEmitter<IInsertaSlider>()
  @Output() envia_actualiza_slider: EventEmitter<IActualizaSlider> = new EventEmitter<IActualizaSlider>()

  imagen_a_mostrar: string = '';
  imagen_para_form_data: File | undefined = undefined;
  icono_cerrar: IconDefinition = faClose
  constructor(
    private _toast: ToastrService
  ){}

  formGroup = new FormGroup({
    id_slider: new FormControl(0),
    titulo: new FormControl<string>(''),
    activo: new FormControl<boolean>(true),
    orden: new FormControl<number>(1),
    texto: new FormControl<string>(''),
    link: new FormControl<string>(''),
    imagen: new FormControl<string>(''),
  });

  ngOnInit() {
    if (!this.es_nuevo && this.slider !== null) {
      this.rellena_formulario(this.slider);
    }
  }

  rellena_formulario(slider: ModelSlider) {
    this.formGroup.patchValue({
      titulo: slider.titulo,
      activo: slider.activo,
      orden: slider.orden,
      texto: slider.texto,
      link: slider.link,
      imagen: slider.imagen
    });
    this.imagen_a_mostrar = slider.imagen;
  }


  borra_imagen() {
    this.formGroup.patchValue({
      imagen: ''
    });
    this.imagen_a_mostrar = ''
    this.imagen_para_form_data = undefined
  }

  formatear_archivo(evento: any): void {
    const upload_event = evento.target;
    if (upload_event.files.length > 0) {
      // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
      const archivo: File = upload_event.files[0];
      const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();

      // Genera un nombre aleatorio para el archivo
      const filename = `${generateRandomName()}.${extension}`;
      const archivo_modificado = new File([archivo], filename, { type: archivo.type })
      this.formGroup.patchValue({ imagen: filename });
      // Asigna el archivo formateado a la propiedad imagen_articulo

      this.imagen_para_form_data = archivo_modificado;

      // Verifica si es una imagen o un PDF para mostrar la vista previa
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        // Si es una imagen, genera una URL temporal para mostrar la vista previa
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagen_a_mostrar = e.target.result

        };
        reader.readAsDataURL(archivo_modificado); // Lee el archivo como Data URL
      } else if (extension === 'pdf') {
        // Si es un PDF, también generamos una URL temporal
        const pdfUrl = URL.createObjectURL(archivo_modificado);
        this.formGroup.patchValue({
          imagen: pdfUrl // Establece la URL del PDF en el formulario
        });
      }
    }
  }

  on_submit() {
    const es_valido = this.valida_formulario() 
    if (es_valido) {
      if (this.es_nuevo) {
        const nuevo = this.formato_nuevo()
        this.envia_nuevo_slider.emit(nuevo)
      } else {
        const actualiza = this.formato_actualiza();
        this.envia_actualiza_slider.emit(actualiza)
      }
    }
  }

  valida_formulario() : boolean {
    if ((this.formGroup.value.imagen === null || this.formGroup.value.imagen === undefined || this.formGroup.value.imagen === '') && this.es_nuevo) {
      this._toast.error('Es obligatorio usar una imagen')
      return false;
    }
    return true;
  }

  formato_nuevo(): IInsertaSlider {
    return {
      titulo: this.formGroup.value.titulo!!,
      activo: this.formGroup.value.activo!!,
      orden: this.formGroup.value.orden!!,
      texto: this.formGroup.value.texto!!,
      link: this.formGroup.value.link!!,
      imagen: this.formGroup.value.imagen!!,
      archivo: this.imagen_para_form_data!!
    }
  }

  formato_actualiza(): IActualizaSlider {
    return {
      titulo: this.formGroup.value.titulo!!,
      activo: this.formGroup.value.activo!!,
      orden: this.formGroup.value.orden!!,
      texto: this.formGroup.value.texto!!,
      link: this.formGroup.value.link!!,
      imagen: this.formGroup.value.imagen!!,
      archivo: this.imagen_para_form_data
    }
  }

}
